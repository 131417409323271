<template>
    <div id="data-list-list-view" class="data-list-container">
      <h1>{{ $t("bills_paid") }}</h1>
      <br>
      <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />
      <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="bills">
  
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
  
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <!-- ADD NEW -->
            
          </div>
  
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ bills.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : bills.length }} of {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
  
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=40">
                <span>40</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=60">
                <span>60</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=80">
                <span>80</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
  
        <template slot="thead">
          <vs-th sort-key="treatment">{{ $t("refFacture") }}</vs-th>
          <vs-th sort-key="patient">{{ $t("patient") }}</vs-th>
          <vs-th sort-key="actes">{{ $t("actes") }}</vs-th>
          <vs-th sort-key="totalFees">{{ $t("totalFees") }}</vs-th>
          <vs-th sort-key="amountToBePaidByInsurance">{{ $t("amountToBePaidByInsurance") }}</vs-th>
          <vs-th sort-key="totalToBePaid">{{ $t("totalToBePaid") }}</vs-th>
          <vs-th sort-key="assured">{{ $t("assured") }}</vs-th>
          <vs-th sort-key="paymentMode">{{ $t("paymentMode") }}</vs-th>
          <vs-th sort-key="paymentType">{{ $t("paymentType") }}</vs-th>
          <vs-th sort-key="treatDate">{{ $t("FacDate") }}</vs-th>
          <vs-th sort-key="PayDate">{{ $t("PayDate") }}</vs-th>
          <vs-th>Action</vs-th>
        </template>
  
        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.name}}</p>
            </vs-td>
           
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.patient.last_name}}{{'  '}}{{tr.patient.first_name}}</p>
            </vs-td>
            <vs-td>
                <li v-for="items in tr.actes" :key="items.id" class="product-name font-medium truncate">{{ items.name}}</li>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.totalFees}} FCFA</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.amountToBePaidByInsurance}} FCFA</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.totalToBePaid}} FCFA</p>
            </vs-td>
            <vs-td>
              <vs-chip :color="getOrderStatusColor1(tr.patient.insured)" class="product-name font-medium truncate">
                {{assurance_choice[tr.patient.insured]}}
              </vs-chip>
            </vs-td>
            
            <vs-td>
              <p class="product-name font-medium truncate">{{paiment_mode[tr.paymentMode] || '-'}} </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{paiment_choice[tr.paymentType]}}</p>
            </vs-td>
            <vs-td>
              <vs-chip color="primary">
                <vs-avatar icon-pack="feather" icon="icon-edit-2" />
                <p class="product-name font-medium truncate">{{tr.created_at | date_time}}</p>
                </vs-chip>
            </vs-td>
            <vs-td>
              <vs-chip color="warning">
                <vs-avatar icon-pack="feather" icon="icon-dollar-sign" />
                <p class="product-name font-medium truncate">{{tr.updated_at | date_time}}</p>
                </vs-chip>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <div class="flex">
                <router-link to="/InvoiceBills">
                <vx-tooltip text="Voir" color="primary">
                 <feather-icon 
                     style="color: navy " 
                     icon="EyeIcon" 
                     svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      v-on:click="openPlayerDialog(tr)" 
                      color="warring" type="border"
                      class="ml-2" />
                  </vx-tooltip>
                </router-link>
                
              </div>
            </vs-td>
          </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </template>
<script>
  
import modulePrescription from '@/store/prescription/modulePrescription.js'

export default {

  data () {
    return {
      paiment_mode: {
        Species: 'Espèce',
        check: 'Chèque',
        card:'Carte',
        null:'Rien'
      },
      paiment_choice: {
        thirdPartyPayment: 'Tiers payant',
        instantPayment: 'paiement comptant',
        null:'-'
      },
      assurance_choice: {
        true: 'Assuré(e)',
        false: 'Non Assuré(e)'
      },
      bills: [],
      selected: [],
      itemsPerPage: 20,
      isMounted: false,
      addNewDataSidebar: false,
      sidebarData: {}
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.bills.length
    }
  },
  methods: {
    openPlayerDialog (player) {
      this.$store.dispatch('prescription/addBill_invoices', player)

    },
    addNewData () {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    getOrderStatusColor1 (status) {
      if (status === true)   return 'success'
      if (status === false) return 'danger'
    },
   
    editData (data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
  
    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
    },
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('enteteDelete'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`bill-pad/${id}/`)
            .then(response => {
              this.getAllBills_paid()
              window.getPrendTaCom.success(this.$t('SuppressionBill'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('Suppression') })
            })
        }
      })
  
    },
  
    getAllBills_paid () {
      this.$vs.loading()
      this.$http.get('bills-paid/')
        .then((response) => {
          this.bills = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
  async created () {
    window.Bills_paid = this
    this.getAllBills_paid()
    this.$store.registerModule('prescription', modulePrescription)
    document.title = 'MooziMed'

  },
  mounted () {
    this.isMounted = true
  }
}
</script>
  
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
        td{
          padding: 20px;
          &:first-child{
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child{
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check{
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>


<style scoped>

</style>

<style scoped>

</style>
